/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import { Status } from '@af/design-system-docs-ui';
import { CustomItem } from '@atlaskit/menu';
import { media, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { checkCurrentPath, checkCurrentPathAncestor } from '../../utils/check-path';
import { CATEGORY_TITLES } from '../../utils/constants';

import { useSideNavigation } from './context';
import ExpandButton from './expand-button';
import HeadingItem from './heading-item';
import SubNavigation from './subnavigation';

import type { SideNavigationItem } from './index';

const listItemStyles = css({
	margin: 0,
	padding: 0,
	// Allows browser to leave some room above the item when it's scrolled into view
	scrollMarginTop: token('space.200', '16px'),
});

const listItemHeadingStyles = css({
	[media.below.sm]: {
		paddingBlockEnd: token('space.150', '12px'),
		paddingInlineStart: token('space.150', '12px'),
	},
});

// NB - I don't want to use `cssFn` and this is the least bad way
// to get specificity to win.
const navLinkStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minHeight: '2rem',
		paddingInlineEnd: token('space.100'),
	},
});

const navLinkWithNoExpandStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingInlineEnd: `calc(${token('space.100')} + ${token('space.500')})`,
	},
});

const selectedStyle = css({
	boxShadow: `inset 4px 0px 0px ${token('color.border.selected', '#0c66e4')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		background: token('color.background.selected', '#e9f2ff'),
		color: token('color.text.selected', '#0c66e4'),
	},
});

const linkWrapperStyle = css({
	display: 'flex',
	position: 'relative',
	// "stretch" is the default, but
	// making it clear that we want the expand button
	// and the main interactive element to be the same height
	alignItems: 'stretch',
});

type NavigationItemProps = {
	nestingLevel: number;
	testId?: string;
	/**
	 * If the item's parent SubNavigation is open or expanded.
	 */
	isParentOpen?: boolean;
} & SideNavigationItem;

/**
 * __Navigation item__
 *
 * A navigation item that recursively renders SubNavigations.
 */
const NavigationItem = ({
	item,
	subitems,
	nestingLevel,
	testId,
	isParentOpen,
}: NavigationItemProps) => {
	const { pathname } = useLocation();
	const { device } = useSideNavigation();
	const isMounted = useRef(false);
	const listItem = useRef<HTMLLIElement>(null);

	const isHeading = item.type === 'heading';
	const isHeadingWithoutLink = isHeading && item.to === undefined;
	const isNonInteractiveHeading =
		(device === 'desktop' && isHeading) || (device === 'mobile' && isHeadingWithoutLink);

	const isCurrentPage = item.to !== undefined && checkCurrentPath(item.to, pathname, subitems);
	const isCurrentPageAncestor =
		item.to !== undefined && checkCurrentPathAncestor(item.to, pathname);

	const [isOpen, setIsOpen] = useState<boolean>(
		isNonInteractiveHeading || isCurrentPage || isCurrentPageAncestor,
	);

	const handleToggleExpand = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	useEffect(() => {
		const isCurrentPage = item.to !== undefined && checkCurrentPath(item.to, pathname, subitems);
		const isCurrentPageAncestor =
			item.to !== undefined && checkCurrentPathAncestor(item.to, pathname);

		setIsOpen(isNonInteractiveHeading || isCurrentPage || isCurrentPageAncestor);
	}, [pathname, item.to, subitems, isNonInteractiveHeading]);

	useEffect(() => {
		// If this item is the current page, scroll it into view
		// FIXME: Unknown why this doesn't work without the setTimeout
		setTimeout(() => {
			if (
				listItem.current &&
				isCurrentPage &&
				!isMounted.current &&
				listItem.current.getBoundingClientRect().top > window.innerHeight
			) {
				listItem.current?.scrollIntoView();
			}

			isMounted.current = true;
		}, 1);
	}, [isCurrentPage]);

	const hasExpandButton = subitems.length > 0;

	let navItem: ReactNode = isNonInteractiveHeading ? (
		<HeadingItem testId={testId ? `${testId}_navigation-item-heading` : undefined}>
			{item.title}
		</HeadingItem>
	) : (
		<div css={linkWrapperStyle}>
			{/* @ts-expect-error prop issues */}
			<CustomItem
				testId={testId ? `${testId}_navigation-item` : undefined}
				isSelected={isCurrentPage}
				iconAfter={
					item.status && (
						<Status type={item.status.type} testId={testId ? `${testId}_status` : undefined} />
					)
				}
				component={({
					wrapperClass,
					children,
					...rest
				}: {
					wrapperClass: string;
					children: React.ReactNode;
				}) => {
					/**
					 * The standard left padding is 2rem,
					 * with an extra 1rem for each level of nesting.
					 */
					let paddingInlineStartRaw = 2;
					if (nestingLevel > 0) {
						paddingInlineStartRaw += nestingLevel;
					}

					const paddingInlineStart = `${paddingInlineStartRaw}rem`;

					// TODO: Convert to Anchor and Pressable when we can support the
					// CustomItem `wrapperClass` styles in primitives / XCSS.
					return item.to ? (
						<Link
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={wrapperClass}
							to={item.to}
							{...rest}
							css={[
								navLinkStyles,
								!hasExpandButton && navLinkWithNoExpandStyles,
								isCurrentPage && selectedStyle,
							]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ paddingInlineStart }}
							aria-current={isCurrentPage ? 'page' : undefined}
							tabIndex={isParentOpen ? undefined : -1}
						>
							{children}
						</Link>
					) : (
						<button
							type="button"
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={wrapperClass}
							{...rest}
							onClick={handleToggleExpand}
							css={[
								navLinkStyles,
								!hasExpandButton && navLinkWithNoExpandStyles,
								isCurrentPage && selectedStyle,
							]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ paddingInlineStart }}
							tabIndex={isParentOpen ? undefined : -1}
						>
							{children}
						</button>
					);
				}}
			>
				<Text weight="medium">{item.title}</Text>
			</CustomItem>

			{hasExpandButton && (
				<ExpandButton
					isOpen={isOpen}
					isSelected={isCurrentPage}
					onClick={handleToggleExpand}
					tabIndex={isParentOpen ? undefined : -1}
					title={item.title}
				/>
			)}
		</div>
	);

	// Don't display the Components sub heading
	// otherwise it doubles up with the "Components" heading above.
	// We should review this pages structure...
	if (!isHeadingWithoutLink && device === 'desktop' && item.title === CATEGORY_TITLES.COMPONENTS) {
		navItem = null;
	}

	return (
		<li
			ref={listItem}
			key={item.slug || item.title}
			css={[listItemStyles, isNonInteractiveHeading && listItemHeadingStyles]}
		>
			{navItem}
			<SubNavigation
				testId={testId ? `${testId}_sub-navigation` : undefined}
				subitems={subitems}
				nestingLevel={
					// Don't increase nesting after non interactive headings
					isNonInteractiveHeading ? nestingLevel : nestingLevel + 1
				}
				isOpen={isOpen}
			/>
		</li>
	);
};

export default NavigationItem;
